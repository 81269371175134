<template>
  <main-layout>
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">CLIENTS</h1>
      <router-link
        to="/addclient"
        class="d-none d-sm-inline-block btn btn-sm btn-success shadow-sm"
        ><i class="fas fa-plus fa-sm text-white-50"></i>Add Client</router-link
      >
    </div>
    <div class="bg-white border rounded">
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Client Name</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="client in clients" :key="client._id">
            <td>{{ client.name }}</td>
            <td>
              <router-link
                :to="`/editclient/${client._id}`"
                class="btn btn-warning mr-2"
                >Edit</router-link
              ><button
                @click.prevent="deleteClient(client._id)"
                class="btn btn-danger"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </main-layout>
</template>

<script>
import axios from "axios";
import MainLayout from "@/components/layout/MainLayout";
export default {
  name: "Clients",
  data() {
    return {
      clients: [],
    };
  },
  methods: {
    async getClients() {
      try {
        const res = await axios.get("/api/v1/clients");
        if (res.status === 200) {
          this.clients = res.data.clients;
        }
      } catch (e) {
        //   ---------------- HANDLE ERROR
        console.log(e);
      }
    },
    async deleteClient(id) {
      try {
        if (confirm("Are you sure?")) {
          const res = await axios.delete(`/api/v1/clients/${id}`, {
            headers: {
              authorization: `bearer ${this.$store.state.token}`,
            },
          });
          this.getClients();
        }
      } catch (e) {
        // ------ HANDLE ERROR
        console.log(e);
      }
    },
  },
  mounted() {
    this.getClients();
  },
  components: {
    MainLayout,
  },
};
</script>

<style>
</style>